import {getSize, isMobile} from '../utilites/helpers'

$(function () {

	const
		header = $('header.header'),
		logotype = $('.header-logotype'),
		intro = $('.intro'),
		scrollDown = $('.intro-scroll-down'),
		howImage = $('.how-intro__image'),
		howText = $('.how-intro__text'),
		headerLinks = $('.header-nav__link'),
		$window = $(window)
	;

	intro.css({
		paddingTop: getSize(header).height
	});


	scrollDown.css({
		left: logotype.offset().left + getSize(logotype).width / 4
	});

	if (getSize($window).width > 991) {
		howText.css({
			paddingLeft: getSize(howImage).width * 1.4
		});

		if (getSize(howImage).height > getSize(howText).height) {
			howText.css({
				marginTop: getSize(howImage).height - getSize(howText).height
			});
		}
	}



	const headerHeight = getSize(header).height;

	headerLinks.on('click', function (event) {

		event.preventDefault();

		let targetId = $(this).attr('href');

		let target = $(targetId).offset().top - headerHeight;

		$('html, body').animate({ scrollTop: target }, 1100);

	});



	let h__flag = false;


	function checkScroll(scroll) {
		if (scroll > headerHeight) {
			if (!h__flag) {

				header.addClass('scrolled');

				h__flag = true
			}
		} else {

			if (h__flag) {

				header.removeClass('scrolled');

				h__flag = false
			}

		}

	}

	checkScroll($window.scrollTop());

	$window.on('scroll', function () {

		checkScroll($(this).scrollTop())


	});

});