import 'svgxuse';
import 'jquery/src/core/init';
import 'jquery/src/core/parseHTML';
import 'jquery/src/manipulation';
import 'jquery/src/deferred';
import 'jquery/src/event';
import 'jquery/src/selector';
import 'jquery/src/wrap';

import './modules/common.js';

